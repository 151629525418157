.editor-wrapper{
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 50% 50%;
  justify-content: center;

  .editor-content-display{
    border: lightgray 5px solid;
    width: 95%;
  }
}

