.edit-menu-wrapper{
  color: white;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 10px lightgray;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display:none;
  }

  li{
    list-style: none;
    text-align: center;
    padding: 15px 0;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }
}