.flex-row{
  display: flex;
  justify-content: space-between;
}

.addContentBtn{
  background: lightgray;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  display: block;
  margin: 50px auto 0;
  color: white;
  font-size: 30px;
  cursor: pointer;

  &:active{
    background: gray;
  }
}

.add-menu{
  background: lightsalmon;
  padding: 15px;
  width: 750px;
  margin: 0 auto;

  li{
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: white;
    cursor: pointer;
    margin-bottom: 50px;

    ul{background: white;}
    h3{margin-bottom: 15px;}
    li{
      width: 100%;
      margin-bottom: 0; 
      color: lightsalmon;
      border-left: 1px solid lightsalmon;
    }

    &:nth-child(2n + 2){
      color: lightsalmon;
      background: white;
    }
  }
}

.addContentMenu-wrapper{
  position: relative;
}