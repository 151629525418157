header,footer{
  display: flex;
  justify-content: center;
  align-items: center;
}

header{
  background: lightseagreen;
  color: white;
  margin-bottom: 20px;
  
  p{margin-left: 15px;}
}

footer{
  // position: absolute;
  // bottom: 0;
  padding: 50px 0 20px;
  width: 100%;
}