.event-btn{
  background: white;
  color: lightsalmon;
  width: 300px;
  margin: 20px auto;
  cursor: pointer;
}
li.event-btn{
  border: 3px solid lightsalmon;
  border-radius: 20px;
}

.edit-form{
  background: lightseagreen;
  font-size: 15px;
  font-weight: normal;
  cursor: auto;
  text-align: left;
  margin: 0 auto;
  width: 95%;

  form{
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  li > p:first-child{
    font-size: 25px;
    margin-bottom: 50px;
  }

  p{
    text-align: center;
    font-weight: bold;
  }

  .section-title{
    display: inline-block;
    margin: 50px 0 20px;
    border-bottom: 1px solid white;

    &:first-child{margin-top: 0;}
  }

  label,input,textarea{
    display: block;
    width: 300px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  button{
    width: 225px;
    margin: 0 auto;
    background: lightsalmon;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border: white 2px solid;
    padding: 10px;
  }

  label{
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 15px;
    text-align: left;
  }

  input,textarea{
    margin-bottom: 20px;
    font-size: 15px;
    width: 100%;
  }

  textarea{
    height: 200px;
  }
}